import {
  REFERRER_HOSTS,
  SEARCH_ENGINE_HOSTS,
  SESSION_KEY,
  SOCIAL_MEDIA_HOSTS,
  UTM_TAGS_MAP,
  UTM_TAGS_REFERRER_DEFAULT_VALUES,
} from './get-constants.js';

import {
  getUtmParams,
  getUtmParamsWithDefault,
  hasAnyUtmParams,
  hasReferrer,
  sessionExpiredChecker,
} from './helpers.js';

export function initializeSession({ sessionExpirationTime }) {
  const params = new URLSearchParams(window.location.search);

  if (hasAnyUtmParams(params)) {
    createSessionWithUtmParams({ params, sessionExpirationTime });
  } else if (hasReferrer()) {
    createSessionWithReferral({ sessionExpirationTime });
  } else if (sessionExpiredChecker()) {
    createSession({
      utmParams: getUtmParamsWithDefault(params),
      sessionExpirationTime,
    });
  } else {
    updateExpirationDate(sessionExpirationTime);
  }
}

function calcExpirationDate(sessionExpirationTime) {
  const expirationDate = new Date();
  expirationDate.setMinutes(
    expirationDate.getMinutes() + sessionExpirationTime,
  );

  return expirationDate;
}

function updateExpirationDate(sessionExpirationTime) {
  const currentSession = JSON.parse(localStorage.getItem(SESSION_KEY));
  const newSession = {
    ...currentSession,
    expirationDate: calcExpirationDate(sessionExpirationTime),
  };

  localStorage.setItem(SESSION_KEY, JSON.stringify(newSession));
}

export function createSession({ utmParams, sessionExpirationTime }) {
  const session = {
    utm: utmParams,
    expirationDate: calcExpirationDate(sessionExpirationTime),
  };

  localStorage.setItem(SESSION_KEY, JSON.stringify(session));
}

export function createSessionWithUtmParams({ params, sessionExpirationTime }) {
  const utmParams = getUtmParams(params);
  createSession({ utmParams, sessionExpirationTime });
}

export function createSessionWithReferral({
  referrer = '',
  sessionExpirationTime,
}) {
  const currentHost = window.location.host;
  const currentReferrerHost = referrer || new URL(document.referrer).host;
  let utm_source =
    currentHost === currentReferrerHost ? 'Direct' : currentReferrerHost;
  let utm_medium, utm_campaign, utm_term, utm_content;

  if (REFERRER_HOSTS.includes(utm_source)) {
    if (SOCIAL_MEDIA_HOSTS.includes(utm_source)) {
      utm_medium = 'Organic Social';
    } else if (SEARCH_ENGINE_HOSTS.includes(utm_source)) {
      utm_medium = 'Organic Search';
    }

    utm_source = utm_source.replace('.com', '').replace('www.', '');
  } else {
    utm_medium = 'Web Referral';
  }

  const utmParams = {
    [UTM_TAGS_MAP.utm_source]:
      utm_source || UTM_TAGS_REFERRER_DEFAULT_VALUES[UTM_TAGS_MAP.utm_source],
    [UTM_TAGS_MAP.utm_medium]:
      utm_medium || UTM_TAGS_REFERRER_DEFAULT_VALUES[UTM_TAGS_MAP.utm_medium],
    [UTM_TAGS_MAP.utm_campaign]:
      utm_campaign ||
      UTM_TAGS_REFERRER_DEFAULT_VALUES[UTM_TAGS_MAP.utm_campaign],
    [UTM_TAGS_MAP.utm_term]:
      utm_term || UTM_TAGS_REFERRER_DEFAULT_VALUES[UTM_TAGS_MAP.utm_term],
    [UTM_TAGS_MAP.utm_content]:
      utm_content || UTM_TAGS_REFERRER_DEFAULT_VALUES[UTM_TAGS_MAP.utm_content],
  };

  createSession({ utmParams, sessionExpirationTime });
}
