export const getFormFields = ({ customFormFields = {} }) => {
  const FORM_FIELD_IDS_MAP = {
    rl_utm_source: customFormFields?.source || 'rl_utm_source',
    rl_utm_medium: customFormFields?.medium || 'rl_utm_medium',
    rl_utm_campaign: customFormFields?.campaign || 'rl_utm_campaign',
    rl_utm_content: customFormFields?.content || 'rl_utm_content',
    rl_utm_term: customFormFields?.term || 'rl_utm_term',
  };
  const FORM_FIELD_IDS_LIST = Object.values(FORM_FIELD_IDS_MAP);
  const FORM_FIELD_IDS_TO_UTM_MAP = {
    [FORM_FIELD_IDS_MAP.rl_utm_source]: UTM_TAGS_MAP.utm_source,
    [FORM_FIELD_IDS_MAP.rl_utm_medium]: UTM_TAGS_MAP.utm_medium,
    [FORM_FIELD_IDS_MAP.rl_utm_campaign]: UTM_TAGS_MAP.utm_campaign,
    [FORM_FIELD_IDS_MAP.rl_utm_content]: UTM_TAGS_MAP.utm_content,
    [FORM_FIELD_IDS_MAP.rl_utm_term]: UTM_TAGS_MAP.utm_term,
  };

  return {
    FORM_FIELD_IDS_MAP,
    FORM_FIELD_IDS_LIST,
    FORM_FIELD_IDS_TO_UTM_MAP,
  };
};

export const UTM_TAGS_MAP = {
  utm_source: 'utm_source',
  utm_medium: 'utm_medium',
  utm_campaign: 'utm_campaign',
  utm_term: 'utm_term',
  utm_content: 'utm_content',
};

export const UTM_TAGS_LIST = Object.values(UTM_TAGS_MAP);

export const SEARCH_ENGINE_HOSTS = [
  'www.google.com',
  'google.com',
  'www.bing.com',
  'bing.com',
  'www.yahoo.com',
  'yahoo.com',
];
export const SOCIAL_MEDIA_HOSTS = [
  'www.facebook.com',
  'facebook.com',
  'www.twitter.com',
  'twitter.com',
  'www.linkedin.com',
  'linkedin.com',
];

export const REFERRER_HOSTS = [...SEARCH_ENGINE_HOSTS, ...SOCIAL_MEDIA_HOSTS];

export const SESSION_KEY = 'session';

export const UTM_TAGS_DEFAULT_VALUES = {
  [UTM_TAGS_MAP.utm_source]: 'Direct',
  [UTM_TAGS_MAP.utm_medium]: 'Direct',
  [UTM_TAGS_MAP.utm_campaign]: 'Not Provided',
  [UTM_TAGS_MAP.utm_term]: '',
  [UTM_TAGS_MAP.utm_content]: '',
};

export const UTM_TAGS_REFERRER_DEFAULT_VALUES = {
  [UTM_TAGS_MAP.utm_source]: 'Web Referral',
  [UTM_TAGS_MAP.utm_medium]: '',
  [UTM_TAGS_MAP.utm_campaign]: 'Not Provided',
  [UTM_TAGS_MAP.utm_term]: '',
  [UTM_TAGS_MAP.utm_content]: '',
};
