import { getUtmParamsFromSession } from './helpers.js';
import { UTM_TAGS_LIST } from './get-constants';

export function appendUrls(appendHostnames) {
  const anchors = getAnchors();

  checkAppend(anchors, appendHostnames);
}

function getAnchors() {
  const anchors = document.getElementsByTagName('a');
  return anchors;
}

function checkAppend(anchors, appendHostnames) {
  const utmParams = getUtmParamsFromSession();

  Array.from(anchors).forEach(anchor => {
    if (anchor.hostname && appendHostnames.includes(anchor.hostname)) {
      const searchParams = new URLSearchParams(anchor.search);

      UTM_TAGS_LIST.forEach(tag => {
        if (utmParams[tag]) {
          searchParams.set(tag, utmParams[tag]);
        }
      });

      anchor.search = searchParams;
    }
  });
}
